import { ref } from "vue";
import supabase from "@/supabase/";

export default {
  install: async (app) => {
    /* Known Rsvps */
    let knownRsvpsRef = ref([]);

    const knownRsvps = {
      ref: knownRsvpsRef,
      get: async () => {
        let { data, error } = await supabase
          .from("known_rsvps")
          .select("*")
          .order("name");
        if (error) throw error;
        knownRsvpsRef.value = data;
      },
      update: async (rsvp, guest_id) => {
        let { error } = await supabase
          .from("known_rsvps")
          .update({ 
            checked: rsvp.checked,
            rsvp: rsvp.rsvp,
            guest_name: rsvp.guest_name,
            dietary_restrictions: rsvp.dietary_restrictions,
            guest_dietary_restrictions: rsvp.guest_dietary_restrictions,
           }, { returning: "minimal" })
          .eq("id", rsvp.id);
        if (error) throw error;

        let { error: secondError } = await supabase
           .from("guests")
           .update({
            rsvp: rsvp.rsvp,
            guest_name: rsvp.guest_name,
            dietary_restrictions: rsvp.dietary_restrictions,
            guest_dietary_restrictions: rsvp.guest_dietary_restrictions
           }, { returning: "minimal" })
           .eq("id", guest_id);

        if (secondError) throw secondError;
      },
    };

    app.provide("knownRsvps", knownRsvps);
    knownRsvps.get();

    /* Unknown Rsvps */
    let unknownRsvpsRef = ref([]);

    const unknownRsvps = {
      ref: unknownRsvpsRef,
      get: async () => {
        let { data, error } = await supabase
          .from("unknown_rsvps")
          .select("*")
          .order("name");
        if (error) throw error;
        unknownRsvpsRef.value = data;
      },
      setChecked: async (id, checked) => {
        let { error } = await supabase
          .from("unknown_rsvps")
          .update({ checked }, { returning: "minimal" })
          .eq("id", id);
        if (error) throw error;
      },
      link: async (rsvp) => {
        let guest_id = rsvp.guest_id;
        let { error } = await supabase
          .from("guests")
          .update({
            rsvp: rsvp.rsvp,
            dietary_restrictions: rsvp.dietary_restrictions,
            guest_name: rsvp.guest_name,
            guest_dietary_restrictions: rsvp.guest_dietary_restrictions
          }, { returning: "minimal" })
          .eq("id", guest_id);

        if (error) throw error;

        let { error: secondError } = await supabase
          .from("unknown_rsvps")
          .update({ 
            guest_id: guest_id,
            name: rsvp.name,
            rsvp: rsvp.rsvp,
            guest_name: rsvp.guest_name,
            dietary_restrictions: rsvp.dietary_restrictions,
            guest_dietary_restrictions: rsvp.guest_dietary_restrictions,
          }, { returning: "minimal" })
          .eq("id", rsvp.id);

        if (secondError) throw secondError;
      },
    };

    app.provide("unknownRsvps", unknownRsvps);
    unknownRsvps.get();

    /* Bundle all getters and resetters */
    const getAll = () => {
      knownRsvps.get();
      unknownRsvps.get();
    };

    const resetAll = () => {
      knownRsvps.ref.value = [];
      unknownRsvps.ref.value = [];
    };

    /* Get or reset upon authentication */
    supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user) {
        getAll();
      } else {
        resetAll();
      }
    });
  },
};
