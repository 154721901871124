import { ref } from 'vue'
import supabase from '@/supabase/';

const user = ref(null);

export default {
    install: (app) => {
        const auth = {
            login: async ({ email, password }) => {
                var { user, error } = await supabase.auth.signIn({ email, password });
                if(error) throw error;
                return user;
            },
            loginWithSocialProvider: async (provider) => {
                var { user, error } = await supabase.auth.signIn({ provider });
                if(error) throw error;
                return user;
            },
            signUp: async ({ email, password }) => {
                var { error } = await supabase.auth.signUp({ email, password });
                if(error) throw error;
                return true;
            },
            signOut: async () => {
                var { error } = await supabase.auth.signOut();
                if(error) throw error;
                return true;
            }
        }
        app.provide('auth', auth);

        supabase.auth.onAuthStateChange((event, session) => {
            user.value = session?.user || null;
        });
    },
    user,
    isLoggedIn: () => {
        return !!user.value;
    }
}