import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import AuthPage from '../views/AuthPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard/'
  },
  { 
    path: '/login',
    component: AuthPage,
  },
  {
    path: '/dashboard/',
    component: TabsPage,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        redirect: '/dashboard/guests'
      },
      {
        path: 'guests',
        component: () => import('@/views/GuestPage.vue')
      },
      {
        path: 'budget',
        component: () => import('@/views/BudgetPage.vue')
      },
      {
        path: 'tasks',
        component: () => import('@/views/TaskPage.vue')
      },
      {
        path: 'rsvps',
        component: () => import('@/views/RsvpPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  /* eslint-disable */
  // @ts-ignore
  let { default: { isLoggedIn } } = await import('../plugins/auth/supabase.js');
  /* eslint-enable */

  if(to.meta.authRequired) {

    if (!isLoggedIn()) {
      return { path: '/login' }
    }
  }

  if(to.path == '/login') {
    if (isLoggedIn()) {
      return { path: '/dashboard/' }
    }
  }
})

export default router
