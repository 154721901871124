import { ref } from 'vue'
import supabase from '@/supabase/';

export default {
    install: async (app) => {
        /* Groups */
        let groupsRef = ref([]);

        const groups = {
            ref: groupsRef,
            get: async () => {
                let { data: data, error } = await supabase.from('groups').select('*').order('name');
                if(error) throw error;
                groupsRef.value = data;
            },
            push: async (newGroup) => {
                let { error } = await supabase.from('groups').insert(newGroup, { returning: "representation" });
                if(error) throw error;
            },
        }

        app.provide('groups', groups);
        groups.get();

        /* Guests */
        let guestsRef = ref([]);

        const guests = {
            ref: guestsRef,
            get: async () => {
                let { data: data, error } = await supabase.from('guests').select('*').order('name');
                if(error) throw error;
                guestsRef.value = data;
            },
            push: async (newGuest) => {
                let { error } = await supabase.from('guests').insert(newGuest, { returning: "minimal" });
                if(error) throw error;
            },
            update: async(newGuest) => {
                let { error } = await supabase.from('guests').update(newGuest, { returning: "minimal" })
                    .eq('id', newGuest.id);
                if(error) throw error;
            },
            delete: async(guestId) => {
                let { error } = await supabase.from('guests').delete({ returning: "minimal" })
                    .eq('id', guestId);
                if(error) throw error;
            }
        }

        app.provide('guests', guests);
        guests.get();

        /* Bundle all getters and resetters */
        const getAll = () => {
            guests.get();
            groups.get();
        }

        const resetAll = () => {
            guests.ref.value = [];
            groups.ref.value = [];
        }

        /* Get or reset upon authentication */
        supabase.auth.onAuthStateChange((event, session) => {
            if(session?.user) {
                getAll();
            } else {
                resetAll(); 
            }
        });
    }
}