<template>
    <ion-header>
        <ion-toolbar color="primary">
            <ion-title v-if="current == 'login'">
                Log in
            </ion-title>
            <ion-title v-if="current == 'register'">
                Registration
            </ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <form>
            <ion-item>
                <ion-label>
                    Email
                </ion-label>
                <ion-input v-model="form.email" placeholder="Email address" class="ion-text-right" />
            </ion-item>
            <ion-item>
                <ion-label>
                    Password
                </ion-label>
                <ion-input v-model="form.password" type="password" placeholder="Password" class="ion-text-right" />
            </ion-item>
            <ion-item v-if="errorMessage != ''" class="error">
                {{ errorMessage }}
            </ion-item>
            <ion-item v-if="successMessage != ''" class="success">
                {{ successMessage }}
            </ion-item>
            <ion-toolbar>
                <ion-buttons>
                    <ion-button color="secondary" @click.prevent="handleLogin('google')">Use Google</ion-button>
                    <ion-button color="secondary" @click.prevent="toggleCurrent" v-if="current == 'login'">Create an account</ion-button>
                    <ion-button color="secondary" @click.prevent="toggleCurrent" v-if="current == 'register'">Log in</ion-button>
                </ion-buttons>
            </ion-toolbar>
            
            <ion-button v-if="current == 'login'" @click="handleLogin()">Log in!</ion-button>
            <ion-button v-if="current == 'register'" @click="handleSignUp()">Register!</ion-button>
        </form>
    </ion-content>
</template>

<script setup>
import { ref, inject } from 'vue';
import { IonHeader, IonTitle, IonContent, IonItem, IonLabel, IonInput, IonButton, IonToolbar, IonButtons, } from '@ionic/vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const auth = inject('auth');

const form = ref({
    email: "",
    password: "",
});

const current = ref('login');
const errorMessage = ref('');
const successMessage = ref('');

const toggleCurrent = () => {
    current.value = current.value == 'login' ? 'register' : 'login';
}

const setErrorMessage = (message) => {
    errorMessage.value = message;
}

const setSuccessMessage = (message) => {
    successMessage.value = message;
}

const handleLogin = async (provider) => {
    try {
        provider
        ? await auth.loginWithSocialProvider(provider)
        : await auth.login(form.value);
        router.push('/');
    } catch (error) {
        setErrorMessage(error.message);
    }
}

const handleSignUp = async () => {
    try {
        if (!['robvanbergen12@gmail.com', '1ezgiyildiz@gmail.com'].includes(form.value.email)) throw { message: "This email address is not invited to log in." };
        await auth.signUp(form.value);

        setSuccessMessage(`Verification link sent to ${form.value.email}`);
    } catch (error) {
        setErrorMessage(error.message);
    }
}
</script>

<style scoped>
.error {
    color: red;
    font-style: italic;
}

.success {
    color: green;
    font-style: italic;
}
</style>