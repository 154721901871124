import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* eslint-disable */
// @ts-ignore
import guests from "./plugins/storage/guests";
// @ts-ignore
import budget from "./plugins/storage/budget";
// @ts-ignore
import tasks from "./plugins/storage/tasks";
// @ts-ignore
import auth from "./plugins/auth/supabase";
// @ts-ignore
import rsvps from "./plugins/storage/rsvps";
// @ts-ignore
import currencyConversion from "./plugins/utils/currencyConversion";
/* eslint-enable */

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Required for vue-virtual-scroller */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
/* eslint-disable */
// @ts-ignore
import { RecycleScroller } from 'vue-virtual-scroller';
/* eslint-enable */

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(currencyConversion)
  .use(guests)
  .use(budget)
  .use(tasks)
  .use(auth)
  .use(rsvps)
  
app.component('RecycleScroller', RecycleScroller);

  router.isReady().then(() => {
  app.mount('#app');
});