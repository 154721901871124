import { ref } from 'vue'
import supabase from '@/supabase/';

const fetchExRate = async (from, to) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", "WetGtga8LMnq7PKnU5f3kIqKGzXgakOk");

    var requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
    }

    return await fetch(`https://api.apilayer.com/exchangerates_data/convert?to=${to}&from=${from}&amount=1`, requestOptions)
        .then(response => response.json())
        .then(result => result.info.rate)
        .catch((error) => console.log('error', error))
}

export default {
    install: async (app) => {
        /* Currency */
        const activeCurrency = ref('EUR');
        const convertedAmount = ref(0);
        const exchangeRates = ref({
            "EURTRY": 1,
            "TRYEUR": 1,
        });

        const currencyConverter = {
            activeCurrency,
            convertedAmount,
            setActive: (code) => {
                activeCurrency.value = code;
            },
            convert: (amount, currency) => {
              if (currency != activeCurrency.value) {
                amount = amount * exchangeRates.value[currency + activeCurrency.value];
              }
              
              return amount;
            },
            beautify: (amount, currency) => {
                if (currency != activeCurrency.value) {
                  amount = amount * exchangeRates.value[currency + activeCurrency.value];
                }

                if (activeCurrency.value == "EUR") {
                    return new Intl.NumberFormat('en-UK', { style: 'currency', currency: "EUR", maximumFractionDigits: 2 }).format(amount);
                } else if (activeCurrency.value == "TRY") {
                    return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: "TRY", maximumFractionDigits: 0 }).format(amount);
                }
            },
        }

        const fetchRates = async () => {
            let date = new Date();
            let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
            let offSet = utcDate.getTime() - date.getTime();
            date.setTime( date.getTime() + offSet );

            var today = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

            var { data, error } = await supabase.from('exchange_rates')
                .select('base, target, rate')
                .eq('created_at', today);

            if(error) throw error;

            if (data.length == 0) {
                var newRates = [
                    {
                        base: "EUR",
                        target: "TRY",
                        rate: await fetchExRate("EUR" ,"TRY")
                    },{
                        base: "TRY",
                        target: "EUR",
                        rate: await fetchExRate("TRY", "EUR")
                    }
                ]

                newRates.forEach(({base, target, rate}) => exchangeRates.value[base + target] = rate )
                
                let { error } = await supabase.from('exchange_rates').insert(newRates);
                if(error) throw error;
            } else {
                data.forEach(({base, target, rate}) => exchangeRates.value[base + target] = rate )
            }
        }
        fetchRates();

        app.provide('currencyConverter', currencyConverter);
    }
}