<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="guests" href="/dashboard/guests">
          <ion-icon :icon="people" />
          <ion-label>Guests</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tasks" href="/dashboard/tasks">
          <ion-icon :icon="checkmarkCircleOutline" />
          <ion-label>Tasks</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="budget" href="/dashboard/budget">
          <ion-icon :icon="cash" />
          <ion-label>Budget</ion-label>
        </ion-tab-button>
        
        <ion-tab-button @click="signOut">
          <ion-icon :icon="exit" />
          <ion-label>Sign out</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import { inject } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { people, exit, cash, checkmarkCircleOutline } from 'ionicons/icons';
import { useRouter } from 'vue-router';

const router = useRouter();

const auth = inject('auth');

const signOut = async () => {
  auth.signOut();
  router.push({ path: '/login' });
}

</script>
