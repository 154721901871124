import { ref } from 'vue'
import supabase from '@/supabase/';

export default {
    install: async (app) => {
        /* Task Categories */
        let taskCategoriesRef = ref([]);

        const task_categories = {
            ref: taskCategoriesRef,
            get: async () => {
                let { data: data, error } = await supabase.from('task_categories').select('*').order('name');
                if(error) throw error;
                taskCategoriesRef.value = data;
            },
            push: async (newTaskCategory) => {
                let { error } = await supabase.from('task_categories').insert(newTaskCategory, { returning: "representation" });
                if(error) throw error;
            },
        }

        app.provide('task_categories', task_categories);
        task_categories.get();

        /* Tasks */
        let tasksRef = ref([]);

        const tasks = {
            ref: tasksRef,
            get: async () => {
                let { data: data, error } = await supabase.from('tasks').select('*').order('finished').order('in_category').order('finish_by');
                if(error) throw error;
                tasksRef.value = data;
            },
            push: async (newTask) => {
                let { error } = await supabase.from('tasks').insert(newTask, { returning: "minimal" });
                if(error) throw error;
            },
            update: async(newTask) => {
                let { error } = await supabase.from('tasks').update(newTask, { returning: "minimal" })
                    .eq('id', newTask.id);
                if(error) throw error;
            },
            delete: async(taskId) => {
                let { error } = await supabase.from('tasks').delete({ returning: "minimal" })
                    .eq('id', taskId);
                if(error) throw error;
            }
        }

        app.provide('tasks', tasks);
        tasks.get();

        /* Bundle all getters and resetters */
        const getAll = () => {
            tasks.get();
            task_categories.get();
        }

        const resetAll = () => {
            tasks.ref.value = [];
            task_categories.ref.value = [];
        }

        /* Get or reset upon authentication */
        supabase.auth.onAuthStateChange((event, session) => {
            if(session?.user) {
                getAll();
            } else {
                resetAll(); 
            }
        });
    }
}