import { ref, toRaw } from 'vue'
import supabase from '@/supabase/';

export default {
    install: async (app) => {
        /* Items */
        let itemsRef = ref([]);

        const items = {
            ref: itemsRef,
            get: async () => {
                let { data: data, error } = await supabase.from('budget_items').select('*, options:budget_options (*)').order('name');
                if(error) throw error;
                itemsRef.value = data;
            },
            push: async (newItem) => {
                let { error } = await supabase.from('budget_items').insert(newItem, { returning: "minimal" });
                if(error) throw error;
            },
            update: async(newItem) => {
                let { error } = await supabase.from('budget_items').update(newItem, { returning: "minimal" })
                    .eq('id', newItem.id);
                if(error) throw error;
            },
            delete: async(itemId) => {
                let { error } = await supabase.from('budget_items').delete({ returning: "minimal" })   .eq('id', itemId);
                if(error) throw error;
            },
            subtotal: async (itemId, currencyConverter, totalGuestsNumber, total=false, marginal=false, average=false, fixed=false, prepaid=false) => {
              let options;

              if (total || marginal || average || fixed || prepaid) {
                options = []

                await toRaw(itemsRef.value).forEach((item) => {
                  options = [...options, ...item.options];
                });

                if (marginal === true) {
                  options = await options.filter(({ per }) => per == "guest");
                }
                
                if (fixed === true) {
                  options = await options.filter(({ per }) => per == "event");
                }

                if (prepaid === true) {
                  options = await options.filter(({ already_paid }) => already_paid > 0);
                }
              } else {
                options = toRaw(itemsRef.value.filter(({ id }) => id == itemId)[0].options);
              }

              let sum = 0;
              await options.forEach(async (option) => {
                let price = option.price;

                if (prepaid) {
                  price = option.already_paid;
                } else if (option.per == "guest") {
                  price = totalGuestsNumber * option.price
                }

                sum = await currencyConverter.convert(price, option.currency) + sum;
              });

              if (average) {
                sum = sum / totalGuestsNumber;
              }
              
              return sum;
            },
            total: async () => {
              return false;
            }
        }

        app.provide('items', items);
        items.get();

        /* Options */
        let optionsRef = ref([]);

        const options = {
            ref: optionsRef,
            push: async (newItem) => {
                delete newItem.id;
                let { error } = await supabase.from('budget_options').insert(newItem, { returning: "minimal" });
                if(error) throw error;
            },
            update: async(newItem) => {
                let { error } = await supabase.from('budget_options').update(newItem, { returning: "minimal" })
                    .eq('id', newItem.id);
                if(error) throw error;
            },
            delete: async(itemId) => {
                let { error } = await supabase.from('budget_options').delete({ returning: "minimal" })
                    .eq('id', itemId);
                if(error) throw error;
            }
        }

        app.provide('options', options);


        /* Bundle all getters and resetters */
        const getAll = () => {
            items.get();
        }

        const resetAll = () => {
            items.ref.value = [];
        }

        /* Get or reset upon authentication */
        supabase.auth.onAuthStateChange((event, session) => {
            if(session?.user) {
                getAll();
            } else {
                resetAll(); 
            }
        });
    }
}